import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DownArrow from '@material-ui/icons/ArrowDownwardSharp';

const useStyles = makeStyles(theme => ({
  btnDiv: {
    position: 'relative',
    top: '80%',
    backgroundColor: 'transparent',
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  fab: {
    backgroundColor: 'transparent',
  },
  arrow: {
    color: '#ffffff',
    backgroundColor: 'transparent',
  }
}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div className={classes.btnDiv} onClick={onClick} >
      <Fab variant="extended" aria-label="scrolldown" className={classes.fab}  >
        <DownArrow className={classes.arrow}  />
      </Fab>
    </div>
  );
}
