import React from 'react';
import axios from 'axios'


import ScrollTopOnMount from '../functional/scrollTopOnMount';



const style = {
  padding: '10px 10px',
  borderBottom: '1px solid black',
  fontFamily: 'Jura, cursive',
}
const API_PATH = 'http://localhost:1992/components/functional/index.php';

class ContactChris extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			message: '',
			mailSent: false,
  			error: null,
  			buttonText: 'Submit'
		}
	}

	handleSubmit(e){
	    e.preventDefault();
	    axios({
	      method: "POST", 
	      url:"http://localhost:3002/send", 
	      data:  this.state
	    }).then((response)=>{
	      if (response.data.status === 'success'){
	        alert("Message Sent."); 
	        this.resetForm()
	      }else if(response.data.status === 'fail'){
	        alert("Message failed to send.")
	      }
	    })
	}
	handleFormSubmit = e => {
	  e.preventDefault();
	  axios({
	    method: 'post',
	    url: `${API_PATH}`,
	    headers: { 'content-type': 'application/json' },
	    data: this.state
	  })
	    .then(result => {
	      this.setState({
	        mailSent: result.data.sent
	      })
	    })
	    .catch(error => this.setState({ error: error.message }));
	};
	resetForm(){   
     	this.setState({name: '', email: '', message: '', buttonText: 'Message Sent'})
  	}
	render() {
		return (
		  <div className="contact-page" style={style} >
		  	<ScrollTopOnMount/>
		  	<form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
			    <div className="form-group">
			        <label htmlFor="name">Name</label>
			        <input type="text" className="form-control"
			        	   value={this.state.name}
			        	   onChange={this.onNameChange.bind(this)}
			        	   placeholder="Your name" />
			    </div>
			    <div className="form-group">
			        <label htmlFor="exampleInputEmail1">Email address</label>
			        <input type="email" className="form-control" aria-describedby="emailHelp"
			        	   value={this.state.email}
			        	   onChange={this.onEmailChange.bind(this)}
			        	   placeholder="Your@email.com" />
			    </div>
			    <div className="form-group">
			        <label htmlFor="message">Message</label>
			        <textarea className="form-control" rows="5"
			         		  value={this.state.message}
			         		  onChange={this.onMessageChange.bind(this)}
			         		  placeholder="Please write your message here" />
			    </div>
			    <button type="submit" className="btn btn-primary">{ this.state.buttonText }</button>
			    <div>
				  {this.state.mailSent &&
				    <div>Thank you for contacting us.</div>
				  }
				</div>
		    </form>
		  </div>
		)
	}
	onNameChange(event) {
		this.setState({name: event.target.value})
	}

	onEmailChange(event) {
		this.setState({email: event.target.value})
	}

	onMessageChange(event) {
		this.setState({message: event.target.value})
	}

}



export default ContactChris