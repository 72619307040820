import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles( theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px'
  },
  table: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  columnTitle: {
    fontFamily: 'Jura, cursive',
    fontSize: '10px',
    color: '#7990a8',
    margin: '0px',
    padding: '0px'
  },
  squareMeters: {
    fontFamily: 'Jura, cursive',
    fontSize:'40px',
    color: '#4f4e4e',
    border: 'none'
  },
  occupancy: {
    fontFamily: 'Jura, cursive',
    fontSize: '24px',
    color: '#4f4e4e',
    border: 'none'
  }
}));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function TableSm(props) {
  const classes = useStyles();

  return (
    <div className={classes.container} >
      <Table className={classes.table} size="small" aria-label="suite specs">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.columnTitle}>m<sup>2</sup></TableCell>
            <TableCell align="center" className={classes.columnTitle}>Occupancy</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={props.name}>
            <TableCell align="center" className={classes.squareMeters}>{props.squareMeters}</TableCell>
            <TableCell align="center" className={classes.occupancy}>{props.occupancy}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
