import React from 'react';
import ScrollTopOnMount from '../functional/scrollTopOnMount';
import {SuiteInside} from './SuiteInside';

import s1 from '../img/vlsm/slider/s1.jpg';
import s2 from '../img/vlsm/slider/s2.jpg';
import s3 from '../img/vlsm/slider/s3.jpg';
import s4 from '../img/vlsm/slider/s4.jpg';
import s5 from '../img/vlsm/slider/s5.jpg';
import s6 from '../img/vlsm/slider/s6.jpg';
import s7 from '../img/vlsm/slider/s7.jpg';
import s8 from '../img/vlsm/slider/s8.jpg';
import s9 from '../img/vlsm/slider/s9.jpg';
import s10 from '../img/vlsm/slider/s10.jpg';

import g1 from '../img/vlsm/g1.jpg';
import g2 from '../img/vlsm/g2.jpg';
import g3 from '../img/vlsm/g3.jpg';
import g4 from '../img/vlsm/g4.jpg';
import g5 from '../img/vlsm/g5.jpg';
import g6 from '../img/vlsm/g6.jpg';
import g7 from '../img/vlsm/g7.jpg';
import g8 from '../img/vlsm/g8.jpg';
import g9 from '../img/vlsm/g9.jpg';
import g10 from '../img/vlsm/g10.jpg';
// import g11 from '../img/vlsm/g11.jpg';
import g11 from '../img/vlsm/g11b.jpg';
import g12 from '../img/vlsm/g12.jpg';
import g13 from '../img/vlsm/g13.jpg';
import g14 from '../img/vlsm/g14.jpg';
import g15 from '../img/vlsm/g15.jpg';
import g16 from '../img/vlsm/g16.jpg';
import g17 from '../img/vlsm/g17.jpg';
import g18 from '../img/vlsm/g18.jpg';
import g19 from '../img/vlsm/g19.jpg';
import g20 from '../img/vlsm/g20.jpg';
import g21 from '../img/vlsm/g21.jpg';
import g22 from '../img/vlsm/g22.jpg';
import g23 from '../img/vlsm/g23.jpg';
import g24 from '../img/vlsm/g24.jpg';
import g25 from '../img/vlsm/g25.jpg';
import g26 from '../img/vlsm/g26.jpg';
import g27 from '../img/vlsm/g27.jpg';
import g28 from '../img/vlsm/g28.jpg';
import g29 from '../img/vlsm/g29.jpg';
import g30 from '../img/vlsm/g30.jpg';
import g31 from '../img/vlsm/g31.jpg';
import g32 from '../img/vlsm/g32.jpg';
import g33 from '../img/vlsm/g33.jpg';

// import CircularProgress from '@material-ui/core/CircularProgress';

// import {useState, useEffect, useRef} from 'react';

// const style={ width:'500px', height: '100%', paddingTop: '300px'}
// const style2={ paddingTop: '300px'}
// function Test() {
//   const [loading, setLoading] = useState(true);
//   const imageLoaded = () => {
//       setLoading(false);
//   }

//   return <React.Fragment>
//     <div style={{display: loading ? "block" : "none"}}>
//        <CircularProgress style={style2} />
//     </div>
//     <div style={{display: loading ? "none" : "block"}}>
//         <img src={s6} style={style} alt={'lalala'} onLoad={imageLoaded}/>
//         <img src={s3} style={style} alt={'lalala'} onLoad={imageLoaded}/>
//         <img src={s1} style={style} alt={'lalala'} onLoad={imageLoaded}/>
//     </div>
//   </React.Fragment>;
// }

function Valsamo() {
  return (
  <div className="suite-page">
    <ScrollTopOnMount/>
	<SuiteInside
	     name={'Valsamo'}
	     slide1 = { s1 } 
	     slide2 = { s2 }
	     slide3 = { s3 }
	     slide4 = { s4 }
	     slide5 = { s5 }
	     slide6 = { s6 }
	     slide7 = { s7 }
	     slide8 = { s8 }
	     slide9 = { s9 }
	     slide10 = { s10 }
	     subtitle={`Deluxe Two Bedroom Villa with Private Outdoor Hot Tub`}
	     descMain= {`Experience the real side of Santorini by staying in this beautiful traditional cave suite. Newly renovated and with all the modern amenities. Relax and enjoy the private jacuzzi on the patio.\n`}
	     desc={`The suite boasts an impressive entrance below the church of Aghios Dimitrios. Valsamo cave house is characterized by the bold Cycladic architecture evident throughout. The external area is striking with
	      the white-washed walls and sense of absolute serenity. Dive into your very own private jacuzzi. The suite is the perfect combination of a traditional cave house while having all the modern amenities. The living
	      room opens up to the small kitchen and the first bedroom which has a spacious and impressive red stucco bathroom. The second bedroom is completely separate and has its own spacious bathroom with a striking
	      shower. This suite also boasts a small balcony with an incredible view of the southern half of the island.`}
	     ideal= {`This suite is ideal for couples and families.`}
	     link={`/valsamo`}
	     squareMeters={`80`}
	     occupancy={`4 guests`}
	     special1 = { `patio with  private jetted tab` }
	     special2 = { `separate bedrooms with en-suite bathrooms` }
	     special3 = { `detached balcony with island and sea view` }
	     special4 = { `full kitchenette` }
	     special5 = { `king size beds` }
	     alt = {'Valsamo'}
	     specialsCount = {5}
	     galleryCount = {33}
	     pic1 =  { g1 } 
	     pic2 =  { g2 } 
	     pic3 =  { g3 } 
	     pic4 =  { g4 }
	     pic5 =  { g5 } 
	     pic6 =  { g6 } 
	     pic7 =  { g7 } 
	     pic8 =  { g8 }
	     pic9 =  { g9 }
	     pic10 = { g10 }
	     pic11 = { g11 }
	     pic12 = { g12 }
	     pic13 = { g13 }
	     pic14 = { g14 }
	     pic15 = { g15 }
	     pic16 = { g16 }
	     pic17 = { g17 }
	     pic18 = { g18 }
	     pic19 = { g19 }
	     pic20 = { g20 }
	     pic21 = { g21 }
	     pic22 = { g22 }
	     pic23 = { g23 }
	     pic24 = { g24 }
	     pic25 = { g25 }
	     pic26 = { g26 }
	     pic27 = { g27 }
	     pic28 = { g28 }
	     pic29 = { g29 }
	     pic30 = { g30 }
	     pic31 = { g31 }
	     pic32 = { g32 }
	     pic33 = { g33 }
	/>
  </div>

)}

export default Valsamo
