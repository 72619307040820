import React from 'react';
import {
  Link
} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhotoIcon from '@material-ui/icons/PhotoAlbum';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import HomeIcon from '@material-ui/icons/HomeRounded';
import MapIcon from '@material-ui/icons/MapRounded';
import ContactIcon from '@material-ui/icons/ContactSupport';
import Slide from '@material-ui/core/Slide';
// import Fade from '@material-ui/core/Fade';
import { HashLink } from 'react-router-hash-link';
import Typography from '@material-ui/core/Typography';



import logo from '../img/valsamo-logo-v.png';
import {Burger} from '../functional/burgerBtn';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  popoverPaper: {    
    height: '94vh',
    maxHeight: 'unset',
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor:'#cccccc',    
  },
})(props => (
  <Menu
    PopoverClasses={{paper: props.classes.popoverPaper}}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    style={{ 
             width: '100%',
             top: '5vh',                         
          }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#68b2bd',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  MuiTypographyBody1: {
    fontFamily: 'Jura, cursive',
  },
  btn: {
    width: '50px',
    height: '50px',
    backgroundColor: '#ffffff',
    margin: '0 5px',
    boxShadow: '1px 1px 2px #4f4e4e',
    "&:hover": {     
        backgroundColor: "#ffffff",
        boxShadow: '2px 2px 4px #4f4e4e',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    }
  },
  dropdown: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  text: {
    fontFamily: 'Jura, cursive !important',
    color: '#4f4e4e',
    fontSize: '26px',
    textDecoration: 'none',
    textAlign: 'center',
    flex: '0 1 auto',
  },
  icon: {
    color: '#0aa4c7',
    fontSize: '20px'
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Jura, cursive',
  },
  valsamoMenu: {
    width: '190px',
    height: '190px',
    zIndex: '10050',
  },
  valsamoMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    zIndex: '10050'
  },
  menuItem: {
    fontFamily: 'Jura, cursive',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    flex: '1 0 auto'
  }
}));



function DroppingMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Button className={classes.btn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="default"
        style={{ backgroundColor: 'transparent' }}
        onClick={handleClick}
      >
        <Burger />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionComponent={Slide}
        transitionDuration={1000}
        transitionDirection={'right'}
        className={classes.dropdown}    
      >        
        <Link to="/" className={classes.link} onClick={handleClose}>
          <StyledMenuItem className={classes.menuItem} >
            <ListItemIcon className={classes.iconContainer} >
              <HomeIcon fontSize="small" className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" style={{ fontFamily: 'Jura, cursive', }}>Home</Typography>} className={classes.text} />
          </StyledMenuItem>
        </Link>
        <HashLink to="/#valsamo" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} className={classes.link} onClick={handleClose} >
          <StyledMenuItem className={classes.menuItem}>
            <ListItemIcon className={classes.iconContainer}>
              <RoomServiceIcon fontSize="small" className={classes.icon}/>
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" style={{ fontFamily: 'Jura, cursive', }}>Accomodation</Typography>} className={classes.text} style={{fontFamily: 'Jura, cursive !important'}} />
          </StyledMenuItem>
        </HashLink>
        <Link to={`/gallery`} className={classes.link} onClick={handleClose}>
          <StyledMenuItem className={classes.menuItem}>
            <ListItemIcon className={classes.iconContainer}>
              <PhotoIcon fontSize="small" className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" style={{ fontFamily: 'Jura, cursive', }}>Gallery</Typography>} className={classes.text} />
          </StyledMenuItem>
        </Link>
        <Link to={`/map`} className={classes.link} onClick={handleClose}>
          <StyledMenuItem className={classes.menuItem}>
            <ListItemIcon className={classes.iconContainer}>
              <MapIcon fontSize="small" className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" style={{ fontFamily: 'Jura, cursive', }}>Map</Typography>} className={classes.text} />
          </StyledMenuItem>
        </Link>
        <HashLink to="/#contact" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} className={classes.link} onClick={handleClose} >
        <StyledMenuItem className={classes.menuItem}>
          <ListItemIcon className={classes.iconContainer}>
            <ContactIcon fontSize="small" className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={<Typography type="body2" style={{ fontFamily: 'Jura, cursive', }}>Contact</Typography>} className={classes.text} />
        </StyledMenuItem>
        </HashLink>
        <div className={classes.valsamoMenuContainer}>
          <img src={logo} className={classes.valsamoMenu} alt='Valsamo' />
        </div>
      </StyledMenu>
    </div>
  );
}

export {DroppingMenu};
