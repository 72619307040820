import React from 'react';
import ScrollTopOnMount from '../functional/scrollTopOnMount';
import {SuiteInside} from './SuiteInside';

import s1 from '../img/bkl/slider/s1.jpg';
import s2 from '../img/bkl/slider/s2.jpg';
import s3 from '../img/bkl/slider/s3.jpg';
// import s4 from '../img/bkl/slider/s4.jpg';
import s5 from '../img/bkl/slider/s5.jpg';
import s6 from '../img/bkl/slider/s6.jpg';
import s7 from '../img/bkl/slider/s7.jpg';
import s8 from '../img/bkl/slider/s8.jpg';
import s9 from '../img/bkl/slider/s9.jpg';
import s10 from '../img/bkl/slider/s10.jpg';

import g1 from '../img/bkl/g1.jpg';
import g2 from '../img/bkl/g2.jpg';
import g3 from '../img/bkl/g3.jpg';
import g4 from '../img/bkl/g4.jpg';
import g5 from '../img/bkl/g5.jpg';
import g6 from '../img/bkl/g6.jpg';
import g7 from '../img/bkl/g7.jpg';
import g8 from '../img/bkl/g8.jpg';
import g9 from '../img/bkl/g9.jpg';
import g10 from '../img/bkl/g10.jpg';
import g11 from '../img/bkl/g11.jpg';
import g12 from '../img/bkl/g12.jpg';
import g13 from '../img/bkl/g13.jpg';
import g14 from '../img/bkl/g14.jpg';
import g15 from '../img/bkl/g15.jpg';
import g16 from '../img/bkl/g16.jpg';
import g17 from '../img/bkl/g17.jpg';
import g18 from '../img/bkl/g18.jpg';
import g19 from '../img/bkl/g19.jpg';
import g20 from '../img/bkl/g20.jpg';
import g21 from '../img/bkl/g21.jpg';
import g22 from '../img/bkl/g22.jpg';
// import g23 from '../img/bkl/g23.jpg';
// import g24 from '../img/bkl/g24.jpg';


const style = {

}

const Bakaliko = () => (
  <div className="suite-page" style={style} >
  	<ScrollTopOnMount/>
	<SuiteInside
	     name={'Bakaliko'}
	     slide1 = { s1 } 
	     slide2 = { s2 }
	     slide3 = { s3 }
	     slide4 = { g12 }
	     slide5 = { s5 }
	     slide6 = { s6 }
	     slide7 = { s7 }
	     slide8 = { s8 }
	     slide9 = { s9 }
	     slide10 = { s10 }
	     subtitle={`Superior Suite`}
	     descMain= {`The iconic old grocery store of Pyrgos has been turned into a modern suite for you to enjoy.\n`}
	     desc={`The Bakaliko boasts an impressive industrial open plan look inside and throughout the interior. The character of the old store has been preserved
	     with the large living room and fully equipped kitchen. The main bedroom is an incredibly relaxing white-washed cave. The impressive bathroom features a
	     large shower with the characteristic blue stucco. An additional sleeping space with two single or one king-size bed in the loft just above the living
	     room but keep in mind that the low ceiling is part of its charm.`}
	     ideal= {``}
	     squareMeters={`65`}
	     occupancy={`4 guests`}
	     special1 = { `open plan industrial design` }
	     special2 = { `full kitchenette` }
	     special3 = { `king size beds` }
	     alt = {'The Bakaliko'}
	     specialsCount = {3}
	     galleryCount = {22}
	     pic1 = { g1 } 
	     pic2 = { g2 } 
	     pic3 = { g3 } 
	     pic4 = { g4 }
	     pic5 = { g5 } 
	     pic6 = { g6 } 
	     pic7 = { g7 } 
	     pic8 = { g8 }
	     pic9 = { g9 }
	     pic10 = { g10 }
	     pic11 = { g11 }
	     pic12 = { g12 }
	     pic13 = { g13 }
	     pic14 = { g14 }
	     pic15 = { g15 }
	     pic16 = { g16 }
	     pic17 = { g17 }
	     pic18 = { g18 }
	     pic19 = { g19 }
	     pic20 = { g20 }
	     pic21 = { g21 }
	     pic22 = { g22 }
	     // pic23 = { g23 }
	     // pic24 = { g24 }
	/>
  </div>

)

export default Bakaliko
