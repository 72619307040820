import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    width: '90%',
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 14,
    color: '#0aa4c7'
  },
  pos: {
    marginBottom: 12,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0px',
  },
  text: {
    textAlign: 'justify',
    color: '#4f4e4e'
  }
});

export default function ValsamoCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography className={classes.title} variant="h3" component="h1" >
            {props.cardTitle}
          </Typography>
          <Typography className={classes.pos} variant="p" component="h2" color="textSecondary">
            {props.phonetic}
          </Typography>
          <Typography className={classes.text} variant="p" component="p">
            {props.mainText}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
