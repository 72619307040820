import React from 'react';
import ScrollTopOnMount from '../functional/scrollTopOnMount';
import {SuiteInside} from './SuiteInside';

// import s1 from '../img/adb/slider/s1.jpg';
// import s2 from '../img/adb/slider/s2.jpg';
// import s4 from '../img/adb/slider/s4.jpg';
// import s5 from '../img/adb/slider/s5.jpg';
// import s6 from '../img/adb/slider/s6.jpg';

import g1 from '../img/adb/g1.jpg';
import g2 from '../img/adb/g2.jpg';
import g3 from '../img/adb/g3.jpg';
import g4 from '../img/adb/g4.jpg';
import g5 from '../img/adb/g5.jpg';
import g6 from '../img/adb/g6.jpg';
import g7 from '../img/adb/g7.jpg';
import g8 from '../img/adb/g8.jpg';
import g9 from '../img/adb/g9.jpg';
import g10 from '../img/adb/g10.jpg';
import g11 from '../img/adb/g11.jpg';
import g12 from '../img/adb/g12.jpg';
import g13 from '../img/adb/g13.jpg';
import g14 from '../img/adb/g14.jpg';
import g15 from '../img/adb/g15.jpg';
import g16 from '../img/adb/g16.jpg';
// import g17 from '../img/adb/g17.jpg';

const StJimCave = () => (
  <div className="suite-page">
  	<ScrollTopOnMount/>
	<SuiteInside
	     name={'Agios Dimitrios'}
	     slide1 = { g1 } 
	     slide2 = { g2 }
	     slide3 = { g3 }
	     slide4 = { g4 }
	     slide5 = { g5 }
	     slide6 = { g6 }
	     slide7 = { g7 }
	     slide8 = { g8 }
	     slide9 = { g9 }
	     slide10 = { g10 }
	     subtitle={`Honeymoon Suite with Outdoor Hot Tub & Panoramic Sea View`}
	     descMain= {`The suite is part of an iconic building perched on the southern side of the village of Pyrgos is located next to the church of
	      Saint Dimitrios. It has been a prominent building since the 19th century and has been photographed extensively, while today the yellow
	       windows and doors are a reference point. It has two levels that share an entrance but are completely private.`}
	     desc={`This suite offers an unobstructed view of the whole southern side of the island as well as the village of Pyrgos from the balcony
	      and your private jacuzzi.`}
	     ideal= {`This suite is ideal for couples`}
	     squareMeters={`35`}
	     occupancy={`2 guests`}
	     special1 = { `traditional open plan design` }
	     special2 = { `balcony with panoramic view` }
	     special3 = { `private jacuzzi` }
	     alt= {`Agios Dimitrios`}
	     specialsCount = {3}
	     galleryCount = {16}
	     pic1 = { g1 } 
	     pic2 = { g2 } 
	     pic3 = { g3 } 
	     pic4 = { g4 }
	     pic5 = { g5 } 
	     pic6 = { g6 } 
	     pic7 = { g7 } 
	     pic8 = { g8 }
	     pic9 = { g9 }
	     pic10 = { g10 }
	     pic11 = { g11 }
	     pic12 = { g12 }
	     pic13 = { g13 }
	     pic14 = { g14 }
	     pic15 = { g15 }
	     pic16 = { g16 }
	/>
  </div>

)

export default StJimCave