import React from 'react';

import SimpleMap from './functional/mapHost';
import ValsamoCard from './functional/card';

const style = {
  padding: '10px 10px',
  fontFamily: 'Jura, cursive',
}
let mapStyle = {
	width: '90%'
}

const MapValsamo = () => (
  <div style={style} >
	<ValsamoCard 
		cardTitle="Pyrgos"
		phonetic="/ˈpir - ɣos/"
		mainText="Pyrgos is a traditional medieval village that is built amphitheatrically on a hill and offers amazing views in all directions. It&nbsp; 
		 is very picturesque, with narrow labyrinth-like streets, a Venetian castle and countless churches, charming and capturing the hearts of visiting&nbsp;
		  tourists and its permanent residents alike. It is one of the highest villages on the island and, at the same time one of the coolest on hot&nbsp;
		  summer days. Pyrgos is situated almost in the center of the island, at a similar distance from the cosmopolitan capital, the port, the airport&nbsp;
		  and the beautiful beaches of Perivolos, the Red Beach and Kamari. At a short walking distance, one can enjoy the wonderful view of the Caldera.&nbsp;
		  There are a plethora of options for dining, whether you are looking for traditional or fine dining restaurants. Pyrgos is considered one of&nbsp;
		  the most picturesque and least spoiled by tourism villages of Santorini"
	 />
	<SimpleMap className="map" style={mapStyle} />
  </div>

)


export default MapValsamo