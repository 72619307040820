import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import logo from './img/valsamo-logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="#ffffff" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Valsamo
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    backgroundColor: '#7990a8',
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: '#000000',
    color: '#ffffff'
  },
  icon: {
    color: "#ffffff",
  },
  detailsContainer: {
    display:'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  logoContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '350px'
  },
  avatar: {
    color: '#ffffff',
    backgroundColor: '#5fc4d0',
    margin: '10px 5px',
  },
  detail: {
    display:'flex',
    alignItems: 'center',

  },
  tag: {
    fontFamily: 'Jura, cursive',
    fontSize: '16px',
    color: '#ffffff',
  },
  phrase: {
    fontFamily: 'Arima Madurai, cursive',
    fontSize: '18px',
    fontStyle: 'oblique',
    color: '#ffffff',
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="lg">
        <div className={classes.logoContainer}>
          <img src={logo} alt="Valsamo Suites Santorini" className={classes.logo} />
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.detail}>
                <RoomServiceIcon className="faicon"  />
            <p className={classes.phrase}>&nbsp;&nbsp;get in touch</p>
          </div>
          <div className={classes.detail}>
            <Tooltip title='Phone' placement="bottom">
                <Icon className='fa fa-phone faicon'  />
            </Tooltip>
            <a href="tel:00306945957683" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <p className={classes.tag}>&nbsp;&nbsp;+30 69 45 95 76 83</p>
            </a>
          </div>
          <div className={classes.detail}>
            <Tooltip title='Email' placement="bottom">
                <Icon className='fa fa-envelope faicon'  />
            </Tooltip>
            <a href="mailto:info@valsamosantorini.com" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <p className={classes.tag}>&nbsp;&nbsp;info@valsamosantorini.com</p>
            </a>
          </div>
          <div className={classes.detail}>
            <a href="https://www.facebook.com/valsamosuites/" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Tooltip title='facebook' placement="bottom">
                <Icon className='fa fa-facebook faicon'   />
              </Tooltip>
            </a>
            <a href="https://www.instagram.com/valsamosuites/" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Tooltip title='Instagram' placement="bottom">
                <Icon className='fa fa-instagram faicon'  />
              </Tooltip>
            </a>
          </div>
        </div>
      </Container>
      <footer className={classes.footer} id="contact">
        <Container maxWidth="lg" >
          <Copyright className={classes.icon} />
        </Container>
      </footer>
    </div>
  );
}

export {Footer};
