import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import Gallery from './Gallery';
import Valsamo from './structural/Valsamo';
import Bakaliko from './structural/Bakaliko';
import MapValsamo from './Map';
import StJim from './structural/AgiosDimitrios';
import StJimCave from './structural/AgiosDimitriosCave';
// import Kalamia from './structural/Kalamia';
import ContactChris from './functional/contactForm';


const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/gallery' component={Gallery} />     
      <Route path='/map' component={MapValsamo} />
      <Route path='/valsamo' component={Valsamo} />
      <Route path='/bakaliko' component={Bakaliko} />
      <Route path='/agiosDimitrios' component={StJim} />
      <Route path='/agiosDimitriosBalcony' component={StJimCave} />
      {/*<Route path='/kalamia' component={Kalamia} />*/}
      <Route path='/contactUs' component={ContactChris} />
    </Switch>
  </main>

)


export {Main}
