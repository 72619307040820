import React, { createRef } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import TableSm from '../functional/tableSm';
import icon from '../img/valsamo-icon.png';



const useStyles = makeStyles( theme => ({
  cardR: {
    width: '100%',
    margin: '0px auto',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    borderRadius: '0',
    border: '0',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
    },
  },
  name: {
    color: '#a2b2dc',
    fontFamily: 'Arima Madurai, cursive'
  },
  desc: {
    fontFamily: 'Jura, cursive',
    textAlign: 'center',
    padding: '10px',
    color: '#4f4e4e'
  },
  ideal: {
    fontFamily: 'Jura, cursive',
    textAlign: 'center',
    fontWeight: '700',
    color: '#5fc4d0'
  },
  cardactionarea: {
    display: 'flex',
    alignItems: 'middle',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '500px',
    padding: '10px 0px',
    cursor: 'default',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  cardactions: {
    width: '100%',
    display: 'flex',
    alignItems: 'middle',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  link: {
    margin: '0 auto',
    textDecoration: 'none'
  },
  valsamoIcon: {
    width: '30px',
    height: '30px',
  },
  btn: {
    fontFamily: 'Jura, cursive',
    margin: '15px auto',
    color: "#0aa4c7",
    fontWeight: '700',
    position: 'relative',
    padding: '15px 20px',
    transition: 'all .2s ease',
    backgroundColor: '#ffffff',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      display: 'block',
      borderRadius: '28px',
      backgroundColor: '#a2b2dc',
      opacity: '0.4',
      width: '56px',
      height: '56px',
      transition: 'all .3s ease'
    },
    label: {
      position: 'relative',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: '900',
      letterSpacing: '.25em',
      textTransform: 'uppercase',
      verticalAlign: 'middle'
    },
    zoom: {
      position: 'relative',
      top: '0',
      marginLeft: '10px',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      stroke: 'red',
      strokeWidth: '2',
      transform: 'translateX(-20px)',
      transition: 'all .3s ease',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      letteShadow: '1px 1px 1px 1px',
      '&:before': {
        width: '100%',
        backgroundColor: '#a2b2dc',
      },
      zoom: {
        transform: 'translateX(0)'
      }
    },
    '&:active': {
        transform: 'scale(.96)'
    }
  },
  container: {
    flexBasis: '100%',
    backgroundColor:'#ffffff'
  },
  imagearea: {
    height: '500px',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  }

}));

function ImgMediaCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.cardR}>
      <ScrollAnimation animateIn="fadeInLeft" className={classes.container} duration="1" >
        <CardActionArea className={classes.cardactionarea} >
          <CardContent>
            <Link to={props.link} className={classes.link}>
              <Typography gutterBottom variant="h3" component="h1" className={classes.name}>
                {props.name}
              </Typography>
            </Link>
            <Link to={props.link} className={classes.link}>
              <Typography clsssvariant="body2" color="textSecondary" component="p" className={classes.desc}>
                {props.desc}
              </Typography>
            </Link>            
            <Typography clsssvariant="body2" color="textSecondary" component="p" className={classes.desc}>
              {props.descMain}
            </Typography>
            <TableSm name={props.name} squareMeters={props.squareMeters} occupancy={props.occupancy} ></TableSm>
          </CardContent>
          <CardActions className={classes.cardactions}>
            <Link to={props.link} className={classes.link}>
              <Button size="large" className={classes.btn} endIcon={<img src={icon} alt="Valsamo icon" className={classes.valsamoIcon}/>}>
                <span className={classes.label} > Explore the suite </span>
              </Button>
            </Link>
            <a href="https://valsamosantorini.reserve-online.net" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Button size="large" className={classes.btn} endIcon={<img src={icon} alt="Valsamo icon" className={classes.valsamoIcon}/>}>
                <span className={classes.label} > Book now </span>
              </Button>
            </a>
          </CardActions>
        </CardActionArea>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInRight" className={classes.container} duration="1" >
        <CardActionArea className={classes.imagearea} >
            <CardMedia
              component="img"
              height="100%"
              alt={props.name}
              title={props.name}
              image={props.suiteImage}
              className="suiteImage"
            />
        </CardActionArea>
      </ScrollAnimation>

    </Card>
  );
}


class SuiteR extends React.Component {
  constructor(props) {
      super(props)
      this.myRef = createRef()
  }
	render() {
    const { suiteImage, name, descMain, desc, ideal, link, squareMeters, occupancy } = this.props;
		return (
			<div ref={this.myRef}>
				<ImgMediaCard
          suiteImage = { suiteImage }
          name = { name }
          descMain = { descMain }
          desc = { desc }
          ideal = { ideal }
          link = { link }
          squareMeters = { squareMeters }
          occupancy = { occupancy }
         />
			</div>
		);
	}
}

export {SuiteR}


// <ZoomIcon className={classes.zoom} />
