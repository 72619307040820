import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {DroppingMenu} from './structural/Menu';

import logo from './img/valsamo-logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '10vh'
    
  },
  bar: {
  	backgroundColor: '#ffffff',
  	opacity: '0.6',
  	height: '10vh',
  },
  tools: {
  	display: 'flex',
  	justifyContent: 'space-between',
  	padding: '10px'
  },
  clickables: {
  	display: 'flex',
  	justifyContent: 'flex-end'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  bookButton: {
  	fontFamily: 'Jura, cursive',
    width: '120px',
    height: '50px',
    backgroundColor: '#ffffff',
    margin: '0 5px',
    color: '#4f4e4e',
    fontWeight: '400',
    boxShadow: '1px 1px 2px #4f4e4e',
    textDecoration: 'none',
    "&:hover": {     
        backgroundColor: "#ffffff",
        boxShadow: '2px 2px 4px #4f4e4e',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  title: {
    flexGrow: 1,
    textAlign: 'left'
  },
  logo: {
  	padding: '5px',
  },
}));



function ButtonAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.bar} >
        <Toolbar className={classes.tools} >
	    	<Link to="/">
	      		<img src={logo} alt="Valsamo" className='logo' />
	      	</Link>
	        <div className={classes.clickables}>
	          	<a href="https://valsamosantorini.reserve-online.net" target="_blank" rel="noopener noreferrer">       	
	          		<Button className={classes.bookButton} color="teal">Book now</Button>
	          	</a>
	          	<DroppingMenu />
	        </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}


class Header extends React.Component {
	render() {
		return (
			<div>
				<ButtonAppBar />
			</div>
		);
	}
}

export {Header}

