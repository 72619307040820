import React, { createRef, useState, useEffect } from 'react';
// import {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Slideshow from 'react-slidez';
// import Img from 'react-image';
import Loader from 'react-loader-spinner'


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import CircularProgress from '@material-ui/core/CircularProgress';

import TableSm from '../functional/tableSm';
import icon from '../img/valsamo-icon.png';




const useStyles = makeStyles( theme => ({
  card: {
    width: '98%',
    margin: '5px auto',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  name: {
    color: '#a2b2dc',
    fontFamily: 'Arima Madurai, cursive',
    [theme.breakpoints.down('sm')]: {
      fontSize: '42px'
    },    
  },
  subtitle: {
    fontFamily: 'Jura, cursive',
    fontWeight: '700',
    color:'#4f4e4e',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    },
  },
  desc: {
    fontFamily: 'Jura, cursive',
    textAlign: 'left',
    padding: '10px',
    color: '#4f4e4e'
  },
  ideal: {
    fontFamily: 'Jura, cursive',
    textAlign: 'center',
    fontWeight: '700',
    color: '#5fc4d0',
    marginBottom: '20px',
  },
  cardactionarea: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'middle',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'default',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
  },
  cardContent: {
    width: '100%',
  },
  cardactions: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  link: {
    margin: '0 auto',
    textDecoration: 'none'
  },
  valsamoIcon: {
    width: '30px',
    height: '30px',
  },
  valsamoIconSm: {
    width: '20px',
    height: '20px',
  },  
  btn: {
    fontFamily: 'Jura, cursive',
    margin: '15px auto',
    color: "#0aa4c7",
    fontWeight: '700',
    position: 'relative',
    padding: '15px 20px',
    transition: 'all .2s ease',
    backgroundColor: '#ffffff',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      display: 'block',
      borderRadius: '28px',
      backgroundColor: '#a2b2dc',
      opacity: '0.4',
      width: '56px',
      height: '56px',
      transition: 'all .3s ease'
    },
    label: {
      position: 'relative',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: '900',
      letterSpacing: '.25em',
      textTransform: 'uppercase',
      verticalAlign: 'middle'
    },
    zoom: {
      position: 'relative',
      top: '0',
      marginLeft: '10px',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      stroke: 'red',
      strokeWidth: '2',
      transform: 'translateX(-20px)',
      transition: 'all .3s ease',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      letteShadow: '1px 1px 1px 1px',
      '&:before': {
        width: '100%',
        backgroundColor: '#a2b2dc',
      },
      zoom: {
        transform: 'translateX(0)'
      }
    },
    '&:active': {
        transform: 'scale(.96)'
    }        
  },
  container: {
    flexBasis: '100%',
    width: '100%',
  },
  avatar: { color: '#fff', backgroundColor: '#ffffff'},
  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  amenitiesContainer: {
    width: '50%',
    margin: '5px auto',
    padding: '0rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  facilitiesContainer: {
    position:'relative',
    width: '100%',
    margin: '5px auto',
    padding: '0rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  carouselArea: {
    position:'relative',
    height: '700px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  facility: {
    margin: '2px auto',
    width: '100%',
  },
  facilityText: {
    fontSize: '0.8rem',
    fontFamily: 'Jura, cursive',
  },
  facilityIcon: {
    minWidth: '30px',
  },
  facilityGroup: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Jura, cursive',
  },
  amenitiesType: {
    textAlign: 'center',
    fontFamily: 'Jura, cursive',
    fontSize: '14px',
    color: '#7990a8',
  },
  columnList: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0',
    },
  },
  galleryContainer: {
    display: 'grid',
    gridGap: '15px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'minmax(20vh, 220px)',
    justifyItems: 'center',
    gridAutoFlow: 'dense',
    padding:0,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  galleryItem: {
    alignIttems: 'stretch',
    borderRadius: '5px',
    // backgroundPosition: 'center center',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '320px',   
  },
  gridPic: {
    height:'100%',
    borderRadius: '5px',
  },
  gridPicSpecial: {
    width: '100% important',

  },
  carousel: {
    height: '800px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  },
  spinner: {
    alignSelf: 'center',
  },
  sliderLoader: {
    alignSelf: 'center',
  }
}));


function ImgMediaCard(props) {
  const classes = useStyles();
  const specialsCount = props.specialsCount;
  const galleryCount = props.galleryCount;

  const [count, setCount] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount('Timeout called!');
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Card className={classes.card}>
      <ScrollAnimation animateIn="fadeIn" className={classes.container} duration="2" >
        <div className={classes.carouselArea}>
          { count < 3000 ? 
            <Loader
              type="Circles"
              color="#5fc4d0"
              height={150}
              width={150}
              timeout={4000}
              className={classes.sliderLoader}
            /> :
            <Slideshow 
              slides={[props.slide1,props.slide2,props.slide3,props.slide4,props.slide5,
                       props.slide6,props.slide7,props.slide8,props.slide9,props.slide10 ]}
              slideInterval={5000}
              showIndex
              showArrows
              height={'100%'}
              width={'100%'}
              effect={'fade'}
              enableKeyboard
              autoplay
              className={classes.carousel}
            >         
            </Slideshow>
          }
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="false" className={classes.container} initiallyVisible  >
        <CardActionArea className={classes.cardactionarea} >
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h1" component="h1" className={classes.name}>
              {props.name}
            </Typography>
            <Typography variant="h5" component="h5" className={classes.subtitle}>
              {props.subtitle}
            </Typography>
            <Typography clsssvariant="body2" color="textSecondary" component="h5" className={classes.desc}>
              {props.descMain}
            </Typography>
            <Typography clsssvariant="body2" color="textSecondary" component="h5" className={classes.desc}>
              {props.desc}
            </Typography>            
            <Typography clsssvariant="body2" color="textSecondary" component="h5" className={classes.ideal}>
              {props.ideal}
            </Typography>
            <TableSm name={props.name} squareMeters={props.squareMeters} occupancy={props.occupancy} ></TableSm>
            <div className={classes.rowContainer}>
              <div className={classes.amenitiesContainer}>
                <Typography clsssvariant="body2" color="textSecondary" component="h6" className={classes.amenitiesType}>
                  This suite features
                </Typography>          
                <div className={classes.facilitiesContainer}>
                  <List component="nav" aria-label="facilities column 1" className={classes.columnList}>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary={props.special1} classes={{primary:classes.facilityText}} />                 
                      </ListItem>
                    </Paper>
                    { specialsCount >= 2  &&
                      <Paper className={classes.facility}>
                        <ListItem>
                          <ListItemIcon className={classes.facilityIcon}>
                            <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                          </ListItemIcon>                 
                          <ListItemText primary={props.special2} classes={{primary:classes.facilityText}}  />                 
                        </ListItem>
                      </Paper>
                    }
                    { specialsCount >= 3  &&
                      <Paper className={classes.facility}>
                        <ListItem>
                          <ListItemIcon className={classes.facilityIcon}>
                            <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                          </ListItemIcon>                 
                          <ListItemText primary={props.special3} classes={{primary:classes.facilityText}} />                 
                        </ListItem>
                      </Paper>
                    }
                    { specialsCount >= 4  &&
                      <Paper className={classes.facility}>
                        <ListItem>
                          <ListItemIcon className={classes.facilityIcon}>
                            <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                          </ListItemIcon>                 
                          <ListItemText primary={props.special4} classes={{primary:classes.facilityText}}  />                 
                        </ListItem>
                      </Paper>
                    }
                    { specialsCount >= 5  &&
                      <Paper className={classes.facility}>
                        <ListItem>
                          <ListItemIcon className={classes.facilityIcon}>
                            <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                          </ListItemIcon>                 
                          <ListItemText primary={props.special5} classes={{primary:classes.facilityText}}  />                 
                        </ListItem>
                      </Paper>
                    }
                  </List>
                </div>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.amenitiesContainer}>
                <Typography clsssvariant="body2" color="textSecondary" component="h6" className={classes.amenitiesType}>
                  Facilities
                </Typography>          
                <div className={classes.facilitiesContainer}>
                  <List component="nav" aria-label="facilities column 1" className={classes.columnList}>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="air condition" classes={{primary:classes.facilityText}} />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="flat TV" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="free WiFi" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="mini bar" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="safe box" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="luxurious bath amenities" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="iron & ironing board" classes={{primary:classes.facilityText}} />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="private smart phone" classes={{primary:classes.facilityText}} />                 
                      </ListItem>
                    </Paper>                
                  </List>
                  <List component="nav" aria-label="facilities column 2" className={classes.columnList}>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="hair dryer" classes={{primary:classes.facilityText}} />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="bathrobes & slippers" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="luggage storage" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="free public parking" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="pet friendly" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="daily maid service" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="Illy espresso machine" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>                
                  </List>
                </div>
              </div>
              <div className={classes.amenitiesContainer}>
                <Typography clsssvariant="body2" color="textSecondary" component="h6" className={classes.amenitiesType}>
                  Services
                </Typography>          
                <div className={classes.facilitiesContainer}>
                  <List component="nav" aria-label="services column 1" className={classes.columnList}>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="babysitting" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="postal" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="massage" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="hair dresser" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="beauty treatments & therapies" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="wine tasting tours" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="laundry & dry cleaning" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>                
                  </List> 
                  <List component="nav" aria-label="services column 2" className={classes.columnList}>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="private transfers" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="catamaran tours" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="yacht / catamaran hiring" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="diving & snorkeling" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="horseback riding" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="car & ATV rental" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>
                    <Paper className={classes.facility}>
                      <ListItem>
                        <ListItemIcon className={classes.facilityIcon}>
                          <img src={icon} alt="Valsamo icon" className={classes.valsamoIconSm}/>
                        </ListItemIcon>                 
                        <ListItemText primary="ironing" classes={{primary:classes.facilityText}}  />                 
                      </ListItem>
                    </Paper>                
                  </List>             
                </div>
              </div>
            </div>
            <div className="grid-container">
             <ul className={classes.galleryContainer}>
               <li className={classes.galleryItem}>
                  <img src={props.pic1} className={classes.gridPic} alt={props.alt} />
               </li>    
               <li className={classes.galleryItem}>               
                  <img src={props.pic2} className={classes.gridPic} alt={props.alt} />    
               </li>
               <li className={classes.galleryItem}>              
                  <img src={props.pic3} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic4} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic5} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic6} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic7} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic8} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic9} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                  <img src={props.pic10} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic11} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic12} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic13} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic14} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic15} className={classes.gridPic} alt={props.alt} />
               </li>
               <li className={classes.galleryItem}>
                 <img src={props.pic16} className={classes.gridPic} alt={props.alt} />
               </li>
               { galleryCount >= 17  &&
                 <li className={classes.galleryItem + ' ' + classes.gridPicSpecial}>
                   <img src={props.pic17} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 18  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic18} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 19  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic19} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 20  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic20} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 21  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic21} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 22  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic22} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 23  &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic23} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 24  && 
                 <li className={classes.galleryItem}>
                   <img src={props.pic24} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 25 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic25} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 26 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic26} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 27 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic27} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 28 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic28} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 29 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic29} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 30 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic30} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 31 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic31} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 32 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic32} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 33 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic33} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 34 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic34} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 35 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic35} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 36 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic36} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 37 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic37} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 38 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic38} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 39 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic39} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
               { galleryCount >= 40 &&
                 <li className={classes.galleryItem}>
                   <img src={props.pic40} className={classes.gridPic} alt={props.alt} />
                 </li>
               }
             </ul>
            </div>            
          </CardContent>
          <CardActions className={classes.cardactions}>
            <a href="https://valsamosantorini.reserve-online.net" className={classes.link}>
              <Button size="large" className={classes.btn} href="https://valsamosantorini.reserve-online.net"
                      endIcon={<img src={icon} alt="Valsamo icon" className={classes.valsamoIcon}/>}>
                <span className={classes.label} > Book now </span>
              </Button>
            </a>
          </CardActions>
        </CardActionArea>
      </ScrollAnimation>

    </Card>
  );
}


class SuiteInside extends React.Component {
  constructor(props) {
      super(props)
      this.myRef = createRef()  
  }
	render() {
    const { name, descMain, desc, ideal, link, squareMeters, occupancy, subtitle, 
            special1, special2, special3, special4, special5, alt, galleryCount, specialsCount,
            slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10,
            pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, 
            pic11, pic12, pic13, pic14, pic15, pic16, pic17, pic18, pic19, pic20,
            pic21, pic22, pic23, pic24, pic25, pic26, pic27, pic28, pic29, pic30,
            pic31, pic32, pic33, pic34, pic35, pic36, pic37, pic38, pic39, pic40 } = this.props;
		return (
			<div ref={this.myRef}>
				<ImgMediaCard          
          name = { name }
          descMain = { descMain }
          desc = { desc }
          ideal = { ideal }
          link = { link }
          squareMeters = { squareMeters }
          occupancy = { occupancy }
          subtitle = { subtitle }
          special1 = { special1 }
          special2 = { special2 }
          special3 = { special3 }
          special4 = { special4 }
          special5 = { special5 }
          slide1 = { slide1 }
          slide2 = { slide2 }
          slide3 = { slide3 }
          slide4 = { slide4 }
          slide5 = { slide5 }
          slide6 = { slide6 }
          slide7 = { slide7 }
          slide8 = { slide8 }
          slide9 = { slide9 }
          slide10 = { slide10 }
          alt = { alt }
          specialsCount = { specialsCount }
          galleryCount = { galleryCount }
          pic1 = { pic1 }   pic11 = { pic11 }   pic21 = { pic21 }   pic31 = { pic31 }    
          pic2 = { pic2 }   pic12 = { pic12 }   pic22 = { pic22 }   pic32 = { pic32 }
          pic3 = { pic3 }   pic13 = { pic13 }   pic23 = { pic23 }   pic33 = { pic33 }
          pic4 = { pic4 }   pic14 = { pic14 }   pic24 = { pic24 }   pic34 = { pic34 }
          pic5 = { pic5 }   pic15 = { pic15 }   pic25 = { pic25 }   pic35 = { pic35 }
          pic6 = { pic6 }   pic16 = { pic16 }   pic26 = { pic26 }   pic36 = { pic36 }
          pic7 = { pic7 }   pic17 = { pic17 }   pic27 = { pic27 }   pic37 = { pic37 }
          pic8 = { pic8 }   pic18 = { pic18 }   pic28 = { pic28 }   pic38 = { pic38 }
          pic9 = { pic9 }   pic19 = { pic19 }   pic29 = { pic29 }   pic39 = { pic39 }
          pic10 = { pic10 } pic20 = { pic20 }   pic30 = { pic30 }   pic40 = { pic40 }                    
         />
			</div>
		);
	}
}

export {SuiteInside}


