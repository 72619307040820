import React from 'react';
import ScrollTopOnMount from './functional/scrollTopOnMount';
import { makeStyles } from '@material-ui/core/styles';
import Slideshow from 'react-slidez';

import vl1 from './img/vlsm/slider/s1.jpg';
import vl2 from './img/vlsm/slider/s2.jpg';
import vl3 from './img/vlsm/slider/s3.jpg';
import vl4 from './img/vlsm/slider/s4.jpg';
import vl5 from './img/vlsm/slider/s5.jpg';
import vl6 from './img/vlsm/slider/s6.jpg';
import vl7 from './img/vlsm/slider/s7.jpg';
import vl8 from './img/vlsm/slider/s8.jpg';
import vl9 from './img/vlsm/slider/s9.jpg';
import vl10 from './img/vlsm/slider/s10.jpg';
import bk1 from './img/bkl/slider/s1.jpg';
import bk2 from './img/bkl/slider/s2.jpg';
import bk3 from './img/bkl/slider/s3.jpg';
import bk4 from './img/bkl/g13.jpg';
import bk5 from './img/bkl/slider/s5.jpg';
import bk6 from './img/bkl/slider/s6.jpg';
import bk7 from './img/bkl/slider/s7.jpg';
import bk8 from './img/bkl/slider/s8.jpg';
import bk9 from './img/bkl/slider/s9.jpg';
import bk10 from './img/bkl/slider/s10.jpg';
import ac1 from './img/adb/g1.jpg';
import ac2 from './img/adb/g2.jpg';
import ac3 from './img/adb/g3.jpg';
import ac4 from './img/adb/g4.jpg';
import ac5 from './img/adb/g5.jpg';
import ac6 from './img/adb/g6.jpg';
import ac7 from './img/adb/g7.jpg';
import ac8 from './img/adb/g8.jpg';
import ac9 from './img/adb/g9.jpg';
import ac10 from './img/adb/g10.jpg';
import ag1 from './img/adp/slider/s1.jpg';
import ag2 from './img/adp/slider/s2.jpg';
import ag3 from './img/adp/slider/s3.jpg';
import ag4 from './img/adp/slider/s4.jpg';
import ag5 from './img/adp/slider/s5.jpg';
import ag6 from './img/adp/slider/s6.jpg';
import ag7 from './img/adp/slider/s7.jpg';
import ag8 from './img/adp/slider/s8.jpg';
import ag9 from './img/adp/slider/s9.jpg';
import ag10 from './img/adp/slider/s10.jpg';
// import kl1 from './img/klm/g1.jpg';
// import kl2 from './img/klm/g2.jpg';
// import kl3 from './img/klm/g3.jpg';
// import kl4 from './img/klm/g6.jpg';
// import kl5 from './img/klm/g8.jpg';
// import kl6 from './img/klm/g10.jpg';
// import kl7 from './img/klm/g22.jpg';
// import kl8 from './img/klm/g25.jpg';
// import kl9 from './img/klm/g18.jpg';
// import kl10 from './img/klm/g15.jpg';

const useStyles = makeStyles( theme => ({
  page: {

  },
  carouselArea: {
    position:'relative',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '450px',
    },
  },
  grid: {
  	width: '90%',
  	margin: '20px',
  	display: 'grid',
  	gridTemplateColumns: '30% 30% 30%',
  	gridTemplateRows: '25% 25% 25%',
  	justifyContent: 'space-around',	
  	border: '2px solid #ffffff'
  },
  gridPic: {
  	width: '100%'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height: '450px',
    },
  },
}));

const Title = () => (
  <div className="gallery-title">
	Gallery   
  </div> 
);

function Gallery() {
const classes = useStyles();
return(
  <div className={classes.page} >
  	<ScrollTopOnMount/>
  	<div className={classes.carouselArea} >
        <Slideshow
          slides={[vl1,vl2,vl3,vl4,vl5,vl6,vl7,vl8,vl9,vl10,
          		   bk1,bk2,bk3,bk4,bk5,bk6,bk7,bk8,bk9,bk10,
          		   ac1,ac2,ac3,ac4,ac5,ac6,ac7,ac8,ac9,ac10,
          		   ag1,ag2,ag3,ag4,ag5,ag6,ag7,ag8,ag9,ag10
          		 ]}
          slideInterval={5000}
          showArrows
          height={'100%'}
          width={'100%'}
          effect={'fade'}
          enableKeyboard
          autoplay
          showIndex
          className={classes.carousel}
        >
        </Slideshow>
        <Title></Title>
    </div>  
  </div>
)};


export default Gallery

// <div className="grid-container">
// 	<ul className="gallery-container">		
// 		<li className="gallery-item">				
// 			<AlertDialogSlide imageSrc={valsamoPic1} imageCaption="Valsamo"  />
// 		</li>
		
// 		<li className="gallery-item">
// 			<AlertDialogSlide imageSrc={deluxePic1} imageCaption="Valsamo"  />				
// 		</li>
// 		<li className="gallery-item">
// 			<AlertDialogSlide imageSrc={stJimPic1} imageCaption="Agios Dimitrios"  />
// 		</li>
// 		<li className="gallery-item">			
// 			<img src={deluxePic2} style={style.gridPic} alt="Valsamo"/>
// 			<h2 class="part-title">Bakaliko</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={bakalikoPic1} style={style.gridPic} alt="Valsamo"/>
// 			<h2 class="part-title">Bakaliko</h2>				
// 		</li>
// 		<li className="gallery-item">
// 			<img src={valsamoPic1} style={style.gridPic} alt="Valsamo"/>
// 			<h2 class="part-title">Valsamo</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">St. Jim</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 2</h2>
// 		</li>
// 		<li className="gallery-item featured">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 3</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 4</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 5</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 6</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 7</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 8</h2>
// 		</li>
// 		<li className="gallery-item">
// 			<img src={stJimPic1} style={style.gridPic} alt="Valsamo" />
// 			<h2 class="part-title">Picture 9</h2>
// 		</li>
// 	</ul>
// </div>