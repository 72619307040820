import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import Avatar from '@material-ui/core/Avatar';
import logo from '../img/valsamo-icon-t.png';
 
const AnyReactComponent = ({ text }) => <div>{text}</div>;
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 36.385406,
      lng: 25.449437
    },
    zoom: 13
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '60vh', width: '90%', 
                    margin: '0 auto', 
                    border: '3px solid #a2b2dc',
                    borderRadius: '5px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAYQ0i6VKWvHw71YtAn-CsPpbeSmiFlQTg' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          <AnyReactComponent

            
          />
          <Avatar lat={36.383055}
                  lng={25.449767}
                  text="Valsamo"
                  style={{ backgroundColor: 'transparent',
                           border: '3px solid #a2b2dc',                          
                        }}
                  >
            <img src={logo} alt='map-marker' />
          </Avatar>
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default SimpleMap;