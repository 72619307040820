import React, { useRef, useState, useEffect } from 'react';
import Slideshow from 'react-slidez';
import FloatingActionButtons from './functional/floatingActionBtn';
import { makeStyles } from '@material-ui/core/styles';
import {Suite} from './structural/Suite';
import {SuiteR} from './structural/SuiteRight';
import BackToTop from './functional/scrollTop';
import Loader from 'react-loader-spinner'

import s1 from './img/home/slider/1.jpg';
import s2 from './img/home/slider/2.jpg';
// import s3 from './img/home/slider/3.jpg';
import s4 from './img/home/slider/4.jpg';
import s5 from './img/home/slider/5.jpg';
import s6 from './img/home/slider/6.jpg';
import s7 from './img/home/slider/7.jpg';
import s8 from './img/home/slider/8.jpg';
import s9 from './img/home/slider/9.jpg';
import s10 from './img/home/slider/10.jpg';
import s11 from './img/home/slider/11.jpg';
// import s12 from './img/home/slider/12.jpg';
import s12 from './img/adb/g1.jpg';
import adp from './img/adp/slider/s1.jpg';



const scrollToRef = (ref) => {
  console.log(ref.current.offsetTop);
  window.scrollTo({
    left:0,
    top: ref.current.offsetTop-100,
    behavior: 'smooth'
  }); 
} 
const Title = () => (
  <div className="slide-title">
    ‘‘Queen of Aegean pulses and wings <br/>
    With the words to convert the infinite<br/>
    With fire with lava with smoke<br/>
    You found the great lines of your destiny’’<br/>
    <p className="poet">
      Ode to Santorini<br/>
      Od. Elytis
    </p>    
  </div> 
);  

const useStyles = makeStyles(theme => ({
  carouselArea: {
    position:'relative',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
  },
  sliderLoader: {
    alignSelf: 'center',
  }
}));


const Home = () => {
  const classes = useStyles();
  const scrollRef = useRef(null);

  const [count, setCount] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount('Timeout called!');
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  const executeScroll = () => scrollToRef(scrollRef);
   return (
    <div className="homeBg">      
      { count < 4000 ?
        <div className={classes.carouselArea} id="back-to-top-anchor" > 
          <Loader
            type="Circles"
            color="#5fc4d0"
            height={150}
            width={150}
            timeout={4000}
            className={classes.sliderLoader}
          />
        </div> :
        <div className={classes.carouselArea} id="back-to-top-anchor">
          <Slideshow
            slides={[s1,s2,s4,s5,s6,s7,s8,s9,s10,s11,s12]}
            slideInterval={5000}
            showArrows
            height={'100%'}
            width={'100%'}
            effect={'fade'}
            enableKeyboard
            autoplay
            className={classes.carousel}
          >          
          </Slideshow> 
          <Title ></Title>
          <FloatingActionButtons className="btnScrollDown" onClick={executeScroll}  />          
        </div>
      }
      <div className="parallax">
        <p className="parallax-title">Welcome to Valsamo Suites</p>
        <p className="parallax-text">Valsamo is the common name for Hypericum perforatum a soothing herb of the Greek mountains. 
          <br/><br/>
          We use the word Valsamo to metaphorically describe anything that soothes the soul and senses; A therapeutic remedy for the body and soul.
          <br/><br/>
          Valsamo Suites Santorini is a complex of suites located in the heart of the traditional village of Pyrgos, right at the center of Santorini island.
          Ideally merging the distinctive Cycladic tradition with minimalism and modern amenities, Valsamo Suites offer serenity and comfort away from the crowds.
          Our suites feature light colors, arched ceilings, built-in beds and they come with a furnished terrace or balcony
          <br/><br/>
        </p>
      </div>  
      <div ref={scrollRef} ></div>
      <div id='valsamo'></div>  
      <Suite
          name={'Valsamo'} 
          suiteImage={s1} 
          descMain= {`SOPHISTICATED CYCLADIC MINIMALISM`}
          desc={`Deluxe Two Bedroom Villa with Private Outdoor Hot Tub`}            
          ideal= {`Ideal for couples and families.`}
          link={`/valsamo`}
          squareMeters={`80`}
          occupancy={`4 guests`}
       />
      
      <SuiteR 
          name={'The Bakaliko'}
          suiteImage={s8}
          descMain={`THE ICONIC OLD GROCERY STORE`}
          desc={`Superior Suite`}
          ideal={``}
          link={`/bakaliko`}
          squareMeters={`65`}
          occupancy={`4 guests`}
        />
      <Suite 
          name={'Agios Dimitrios'}
          suiteImage={s12}
          descMain= {`A TRIBUTE TO LOCAL ARCHITECTURE`}
          desc={`Honeymoon Suite with Outdoor Hot Tub & Panoramic Sea View`}            
          ideal= {`View of the whole southern side of the island`}
          link={`/agiosDimitriosBalcony`}
          squareMeters={`35`}
          occupancy={`2 guests`}
        />
      <SuiteR 
          name={'Agios Dimitrios'}
          suiteImage={adp}
          descMain={`DELIGHTFULLY TRADITIONAL ISLAND LIFE`}
          desc={`Honeymoon Suite with Outdoor Hot Tub & Sea View`}
          ideal={`Ideal for dining throughout the day`}
          link={`/agiosDimitrios`}
          squareMeters={`35`}
          occupancy={`2 guests`}
        />
      {/*<Suite 
          name={'Kalamia'}
          suiteImage={s3}
          descMain= {`UNPARALLED COMFORT`}
          desc={`Two Bedroom Villa with Jacuzzi and Sea View`}            
          ideal= {`Ideal for families and groups of friends`}
          link={`/kalamia`}
          squareMeters={`90`}
          occupancy={`6 guests`}
        />*/}
      <BackToTop></BackToTop>
    </div>
  )
}




export default Home
