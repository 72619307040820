import React from 'react';
import ScrollTopOnMount from '../functional/scrollTopOnMount';
import {SuiteInside} from './SuiteInside';

import s1 from '../img/adp/slider/s1.jpg';
import s2 from '../img/adp/slider/s2.jpg';
import s3 from '../img/adp/slider/s3.jpg';
import s4 from '../img/adp/slider/s4.jpg';
import s5 from '../img/adp/slider/s5.jpg';
import s6 from '../img/adp/slider/s6.jpg';
import s7 from '../img/adp/slider/s7.jpg';
import s8 from '../img/adp/slider/s8.jpg';
import s9 from '../img/adp/slider/s9.jpg';
import s10 from '../img/adp/slider/s10.jpg';

import g1 from '../img/adp/slider/s1.jpg';
import g2 from '../img/adp/slider/s2.jpg';
import g3 from '../img/adp/slider/s3.jpg';
import g4 from '../img/adp/slider/s4.jpg';
import g5 from '../img/adp/slider/s5.jpg';
import g6 from '../img/adp/slider/s6.jpg';
import g7 from '../img/adp/slider/s7.jpg';
import g8 from '../img/adp/slider/s9.jpg';
import g9 from '../img/adp/slider/s10.jpg';
import g10 from '../img/adp/g11.jpg';
import g11 from '../img/adp/g12.jpg';
import g12 from '../img/adp/g13.jpg';
import g13 from '../img/adp/g14.jpg';
import g14 from '../img/adp/g15.jpg';
import g15 from '../img/adp/g16.jpg';
import g16 from '../img/adp/g17.jpg';
import g17 from '../img/adp/g18.jpg';
// import g19 from '../img/adp/g19.jpg';
// import g20 from '../img/adp/g20.jpg';
// import g21 from '../img/adp/g21.jpg';
// import g22 from '../img/adp/g22.jpg';
// import g23 from '../img/adp/g23.jpg';
// import g24 from '../img/adp/g24.jpg';
// import g25 from '../img/adp/g25.jpg';
// import g26 from '../img/adp/g26.jpg';
// import g27 from '../img/adp/g27.jpg';
// import g28 from '../img/adp/g28.jpg';
// import g29 from '../img/adp/g29.jpg';
// import g30 from '../img/adp/g30.jpg';


const StJim = () => (
  <div className="suite-page">
  	<ScrollTopOnMount/>
	<SuiteInside
	     name={'Agios Dimitrios'}
	     slide1 = { s1 } 
	     slide2 = { s2 }
	     slide3 = { s3 }
	     slide4 = { s4 }
	     slide5 = { s5 }
	     slide6 = { s6 }
	     slide7 = { s7 }
	     slide8 = { s8 }
	     slide9 = { s9 }
	     slide10 = { s10 }
	     subtitle={`Honeymoon Suite with Outdoor Hot Tub & Sea View`}
	     descMain= {`The suite is part of an iconic building perched on the southern side of the village of Pyrgos is located next to the church of
	      Saint Dimitrios. It has been a prominent building since the 19th century and has been photographed extensively, while today the yellow
	       windows and doors are a reference point.`}
	     desc={`It has two levels that share an entrance but are completely private. The suite features an open plan space with a sofa and a queen
	      size bed. The bathroom follows the cave-like character. The veranda with a large private jacuzzi, sun loungers, and an ornate marble
	      table, ideal for dining and relaxing throughout the day.`}
	     ideal= {`This suite is ideal for couples`}
	     squareMeters={`35`}
	     occupancy={`2 guests`}
	     special1 = { `large patio with sea view` }
	     special2 = { `traditional open plan design` }
	     special3 = { `private jacuzzi` }
	     alt= {`Agios Dimitrios`}
	     specialsCount = {3}
	     galleryCount = {17}
	     pic1 = { g1 } 
	     pic2 = { g2 } 
	     pic3 = { g3 } 
	     pic4 = { g4 }
	     pic5 = { g5 } 
	     pic6 = { g6 } 
	     pic7 = { g7 } 
	     pic8 = { g8 }
	     pic9 = { g9 }
	     pic10 = { g10 }
	     pic11 = { g11 }
	     pic12 = { g12 }
	     pic13 = { g13 }
	     pic14 = { g14 }
	     pic15 = { g15 }
	     pic16 = { g16 }
	     pic17 = { g17 }
	     // pic19 = { g19 }
	     // pic20 = { g20 }
	     // pic21 = { g21 }
	     // pic22 = { g22 }
	     // pic23 = { g23 }
	     // pic24 = { g24 }
	     // pic25 = { g25 }
	     // pic26 = { g26 }
	     // pic27 = { g27 }
	     // pic28 = { g28 }
	     // pic29 = { g29 }
	     // pic30 = { g30 }
	/>
  </div>

)

export default StJim